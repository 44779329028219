.bannerContainer {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding: 5px 0;
}

img.bannerBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(30%);
}

.mobileAuthCard {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  border-radius: 25px 25px 0 0;
  padding-top: 25px;

  background-color: var(--bg-body-color);
}
